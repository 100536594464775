import { useUserStore } from '../stores/user-store'
import Router from '../router'
import { useToast } from 'vuestic-ui'

const { init } = useToast()

export const fetcher = {
  get: request('GET'),
  post: request('POST'),
  put: request('PUT'),
  delete: request('DELETE'),
}

function request(method) {
  return async (url, body) => {
    const requestOptions = {
      method,
      headers: authHeader(url),
    }

    if (body) {
      requestOptions.headers['Content-Type'] = 'application/json'
      requestOptions.body = JSON.stringify(body)
    }
    try {
      const response = await fetch(url, requestOptions)
      return handleResponse(response)
    } catch (error) {
      return handleError(error)
    }
  }
}

function authHeader(url) {
  const user = useUserStore()
  const isLoggedIn = !!user?.token
  const isApiUrl = url.startsWith(import.meta.env.VITE_API_URL)
  let headers = {}

  if (isLoggedIn && isApiUrl) {
     headers['Authorization'] = `Bearer ${user.token}`
  }

  headers['yobino-app-client'] = `webApp`
  headers['yobino-app-version'] = (__APP_VERSION__.split('+'))[0]
  headers['yobino-app-build'] = (__APP_VERSION__.split('+'))[1]

  return headers
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text)

    if (!response.ok) {
      const user = useUserStore()
      if ([401, 403].includes(response.status)) {
        console.log('erreur auth')
        user.logout()
        Router.push({ name: 'login' })
      }

      if([426].includes(response.status)) {
        console.log('erreur version')
        if(user.reload <= 2){
          init({ message: 'Version client dépassée. Mise à jour en cours', color: 'warning' })
          user.incrementReload()
          Router.go(0)
        }else{
          user.logout()
          Router.push({ name: 'login' })
        }
      }

      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }

    return data
  })
}

function handleError(error) {
  console.log(error)
}
