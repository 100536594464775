import { defineStore } from 'pinia'
import { Site } from '../pages/yobino/types'
import { fetcher } from '../helpers/fetcher'

const rightsType = [
  {id:0, name:'Utilisateur', icon:'person', color:'primary'}, 
  {id:1, name:'Utilisateur avancé', icon:'person_add', color:'warning'}, 
  {id:3, name:'Administrateur', icon:'supervisor_account', color:'danger'}
]

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      userName: localStorage.getItem('userName') ?? '',
      rights: localStorage.getItem('rights') ?? '0',
      selectedSite: localStorage.getItem('selectedSite') ?? undefined,
      email: localStorage.getItem('email') ?? 'email@obeia.com',
      token: localStorage.getItem('token') ?? '',
      is2FAEnabled: false,
      lang: localStorage.getItem('lang') ?? 'fr',
      sites: undefined as Site[] | undefined,
      reload: localStorage.getItem('reload') ?? '0'
    }
  },
  getters: {
    getSites(state){
        return state.sites
    },

    getSelectedSiteName(state){
      const foundSite = state.sites?.find(site => site.uid === state.selectedSite);
      return foundSite ? foundSite : {uid:'', name:''};
    },

    getRightsLabel(state){
      const rights = rightsType.find((rights) => rights.id == parseInt(state.rights))
      return rights ?? rightsType[0]
    },
    
    getRights(state){
      return parseInt(state.rights)
    },

    getReload(state){
      return parseInt(state.reload)
    }
  },
  actions: {
    toggle2FA() {
      this.is2FAEnabled = !this.is2FAEnabled
    },

    changeUserLang(lang: string) {
      this.lang = lang
      localStorage.setItem('lang', this.lang)
    },

    changeUserRights(rights: string) {
      this.rights = rights
      localStorage.setItem('rights', this.rights)
    },

    changeUserName(userName: string) {
      this.userName = userName
      localStorage.setItem('userName', this.userName)
    },

    changeUserEmail(userEmail: string) {
      this.email = userEmail
      localStorage.setItem('email', this.email)
    },

    changeUserSelectedSite(selectedSite: string) {
      this.selectedSite = selectedSite
      localStorage.setItem('selectedSite', this.selectedSite)
    },

    updateToken(token: string) {
      this.token = token
      localStorage.setItem('token', this.token)
    },

    incrementReload() {
      this.reload = (parseInt(this.reload) + 1).toString()
      localStorage.setItem('reload', this.reload)
    },

    async fetchSites(){
      let sitesObject: Site[] = [{
        uid: '',
        name: ''
      }]
      const sitesFromAPI = await fetcher.get(`${import.meta.env.VITE_API_URL}/yobino/sites`)
      Object.assign(sitesObject, sitesFromAPI)
      this.sites = sitesObject
    },

    logout() {
      localStorage.removeItem('selectedSite')
      localStorage.removeItem('lang')
      localStorage.removeItem('userName')
      localStorage.removeItem('rights')
      localStorage.removeItem('email')
      localStorage.removeItem('token')
      localStorage.removeItem('reload')
    },
  },
})
