import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'

import RouteViewComponent from '../layouts/RouterBypass.vue'
import RouteViewInventoryComponent from '../layouts/RouterInventory.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: '404' },
  },
  {
    name: 'admin',
    path: '/',
    component: AppLayout,
    redirect: { name: 'dashboard' },
    children: [
      {
        name: 'dashboard',
        path: '/dashboard/:siteUID?',
        component: () => import('../pages/yobino/SitePage.vue'),
      },
      {
        name: 'usersList',
        path: '/users',
        component: () => import('../pages/yobino/UsersListPage.vue'),
      },
      {
        name: 'benchmark',
        path: '/benchmark/:siteUID?',
        component: () => import('../pages/yobino/BenchmarkPage.vue'),
      },
      {
        name: 'import',
        path: '/import/:siteUID?',
        component: () => import('../pages/yobino/ImportPage.vue'),
      },
      {
        name: 'roi',
        path: '/roi/:siteUID?',
        component: () => import('../pages/yobino/ROIPage.vue'),
      },
      // {
      //   name: 'preco',
      //   path: '/preco/:siteUID?',
      //   component: () => import('../pages/yobino/PrecoPage.vue'),
      // },
      {
        name: 'actionsPlan',
        path: '/actions/:siteUID?',
        component: () => import('../pages/yobino/ActionsPlanPage.vue'),
      },
      {
        name: 'article',
        path: '/article/:articleUID',
        component: () => import('../pages/yobino/ArticlePage.vue'),
      },
      {
        name: 'inventories',
        path: '/inventories',
        component: RouteViewInventoryComponent,
        children: [
          {
            name: 'inventoryList',
            path: '',
            component: () => import('../pages/yobino/InventoriesPage.vue'),
          },
          {
            name: 'inventoryDetail',
            path: ':uid',
            component: () => import('../pages/yobino/InventoryPage.vue'),
            //component: () => import('../pages/yobino/InventoryPage.vue'),
          },
          // {
          //   name: 'inventorySynthesis',
          //   path: ':uid/synthesis',
          //   component: () => import('../pages/yobino/InventorySynthesisPage.vue'),
          // },
          // {
          //   name: 'approResults',
          //   path: ':uid/appro',
          //   component: () => import('../pages/yobino/PrecoPage.vue'),
          // },
        ],
      },
    ],
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/Login.vue'),
      },
      {
        name: 'signup',
        path: 'magic/:signupToken',
        component: () => import('../pages/auth/Signup.vue'),
      },
      {
        name: 'recover-password',
        path: 'recover-password',
        component: () => import('../pages/auth/RecoverPassword.vue'),
      },
      {
        name: 'recover-password-email',
        path: 'recover-password-email',
        component: () => import('../pages/auth/CheckTheEmail.vue'),
      },
      {
        path: '',
        redirect: { name: 'login' },
      },
    ],
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }

    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

export default router
