<template>
    <VaSelect label="Site" v-model="model" :options="sites" text-by="name" style="max-width: 180px;" track-by="uid">
        <template #content="{ value }">
            <VaChip class="mr-2" size="small">
                {{ value.name }}
            </VaChip>
            <!-- <VaChip v-for="site in value" :key="site" class="mr-2" size="small">
            {{ console.log(site) }}
            </VaChip> -->
        </template>
    </VaSelect>
  </template>
  <script lang="ts" setup>
    import { computed, onMounted, watch } from 'vue'
    import { useUserStore } from '../../../stores/user-store'
    import { Site } from '../types'
    import { useRouter } from 'vue-router'

    const user = useUserStore()
    const router = useRouter()

    const sites = computed(() => {
        return user.sites
    })

    watch(() => user.selectedSite, () => {
        //window.location.replace('/')
        router.push(`/dashboard/${user.selectedSite}`)
    })

    const findSiteByUid = (uid: string): Site | undefined => {
        if (sites.value) {
            const foundSite = sites.value.find(site => site.uid === uid);
            return foundSite ? foundSite : sites.value[0];
        }
        return undefined;
    }

    const model = computed({
        get() {
            if (user.selectedSite) {
                return findSiteByUid(user.selectedSite) || {uid:'', name:'Sélectionnez un site'};
            }
            return {uid:'', name:'Aucun site disponible'};
        },
        set(value) {
            user.changeUserSelectedSite(value.uid)
        },
    })

    onMounted(async () => {
        await user.fetchSites()
        if(user.selectedSite == null && sites.value?.length){
            user.changeUserSelectedSite(sites.value[0].uid)
        }
    })
  </script>
  